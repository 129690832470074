var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Toolbar", { attrs: { stageTitle: "Wallet" } }, [
        _c("div", { staticStyle: { "margin-top": "20px" } }, [
          _c("img", {
            attrs: { src: "/img/dashboard/notification-icon.svg", alt: "" },
          }),
        ]),
      ]),
      _vm.teamIncome == null
        ? _c("Loader")
        : _c(
            "div",
            { staticClass: "page-container", on: { click: _vm.removePrompt } },
            [
              _c("div", { staticClass: "payment-overview" }, [
                _c("div", { staticClass: "container-title" }, [
                  _c("div", { staticClass: "container-name" }, [
                    _vm._v("PAYMENTS"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "project-switch",
                      on: {
                        click: function ($event) {
                          return _vm.stayTop($event)
                        },
                      },
                    },
                    [
                      _vm.currentProjectId
                        ? _c("div", { staticClass: "select-dropdown" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.currentProjectId.project_name) +
                                "\n            "
                            ),
                            _c("img", {
                              attrs: {
                                src: "/img/dashboard/drop-down.svg",
                                alt: "",
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.isProjects && _vm.projects.length > 0
                        ? _c(
                            "div",
                            { staticClass: "dropdown-list" },
                            _vm._l(_vm.projects, function (project, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "project-list",
                                  on: {
                                    click: function ($event) {
                                      return _vm.switchProject(project)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(project.project_name) +
                                      "\n            "
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "project-earning" }, [
                  _c("div", { staticClass: "amount-paid" }, [
                    _c("div", { staticClass: "box-title" }, [
                      _vm._v("Total amount paid"),
                    ]),
                    _c("div", { staticClass: "box-figure" }, [
                      _vm._v(
                        "\n            $" +
                          _vm._s(
                            _vm
                              .getCurrentProject()
                              .amountPaid.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "team-received" }, [
                    _c("div", { staticClass: "box-title" }, [
                      _vm._v(
                        "\n            Your team received (\n            " +
                          _vm._s(
                            _vm.projects[0].percentage_cut
                              ? 100 - _vm.projects[0].percentage_cut + "%"
                              : ""
                          ) +
                          "\n            )\n          "
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "box-figure",
                        staticStyle: { color: "#54cf94" },
                      },
                      [
                        _vm._v(
                          "\n            $" +
                            _vm._s(
                              _vm
                                .getCurrentProject()
                                .teamCut.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                })
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "grupa-cut" }, [
                    _c("div", { staticClass: "box-title" }, [
                      _vm._v(
                        "\n            Grupa got (" +
                          _vm._s(
                            _vm.projects[0].percentage_cut
                              ? _vm.projects[0].percentage_cut + "%"
                              : ""
                          ) +
                          ")\n          "
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "box-figure",
                        staticStyle: { color: "#d45b54" },
                      },
                      [
                        _vm._v(
                          "\n            $" +
                            _vm._s(
                              (
                                _vm.getCurrentProject().amountPaid * 0.2
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "payment-history" },
                  [
                    _c(
                      "PaymentTable",
                      _vm._b(
                        {},
                        "PaymentTable",
                        {
                          teamIncome: _vm.teamIncome,
                          currentProjectId: _vm.currentProjectId,
                        },
                        false
                      )
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "wallet-balance" },
                [
                  _c(
                    "TeamBalance",
                    _vm._b(
                      {},
                      "TeamBalance",
                      { totalBalance: _vm.totalBalance },
                      false
                    )
                  ),
                  _c("TeamWithdrawal"),
                ],
                1
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }