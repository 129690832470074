var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "payment-history" }, [
    _c(
      "div",
      { staticClass: "payment-table" },
      [
        _vm._m(0),
        _vm._l(_vm.teamIncome, function (project, index) {
          return project.project_id == _vm.currentProjectId.id
            ? _c("div", { key: index, staticClass: "table-row table-border" }, [
                _c("div", { staticClass: "table-data medium-column" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        project.purpose ? project.purpose : "Kickoff fee"
                      ) +
                      "\n      "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "table-data medium-column figure-column" },
                  [
                    _vm._v(
                      "\n        $" +
                        _vm._s(
                          Math.ceil(project.amount * (100 / 80)).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                            }
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _c("div", { staticClass: "table-data figure-column" }, [
                  _vm._v(
                    "\n        $" +
                      _vm._s(project.amount.toLocaleString()) +
                      "\n      "
                  ),
                ]),
                _c("div", { staticClass: "table-data last-column" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$moment(project.updated_at).format("DD/MMM/YYYY")
                      ) +
                      "\n      "
                  ),
                ]),
              ])
            : _vm._e()
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-header" }, [
      _c("div", { staticClass: "table-head medium-column" }, [
        _vm._v("Details"),
      ]),
      _c("div", { staticClass: "table-head medium-column" }, [
        _vm._v("Total Amout Paid"),
      ]),
      _c("div", { staticClass: "table-head" }, [_vm._v("Team’s Cut (80%)")]),
      _c("div", { staticClass: "table-head last-column" }, [_vm._v("Date")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }