<template>
  <div class="payment-history">
    <div class="payment-table">
      <div class="table-header">
        <div class="table-head medium-column">Details</div>
        <div class="table-head medium-column">Total Amout Paid</div>
        <div class="table-head">Team’s Cut (80%)</div>
        <div class="table-head last-column">Date</div>
      </div>
      <div
        class="table-row table-border"
        v-for="(project, index) in teamIncome"
        :key="index"
        v-if="project.project_id == currentProjectId.id"
      >
        <!-- :class="{ 'table-border': index !== clientPayments.length - 1 }" -->
        <div class="table-data medium-column">
          {{ project.purpose ? project.purpose : "Kickoff fee" }}
        </div>
        <div class="table-data medium-column figure-column">
          <!-- -->
          ${{
            Math.ceil(project.amount * (100 / 80)).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          }}
        </div>
        <div class="table-data figure-column">
          <!-- ${{ teamGot(project.amount, project.purpose).toLocaleString() }} -->
          ${{ project.amount.toLocaleString() }}
        </div>
        <div class="table-data last-column">
          {{ $moment(project.updated_at).format("DD/MMM/YYYY") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teamIncome: {
      type: Array,
      required: true,
    },
    currentProjectId: {
      type: Object,
    },
  },
  components: {},

  data: () => ({}),

  created() {},
  methods: {
    teamGot(price, purpose) {
      if (purpose.includes("Sprint fee")) {
        return (price / 0.2) * 0.8;
      }
      return price * 0.8;
    },
    actualPrice(price, purpose) {
      if (purpose.includes("Sprint fee")) {
        return price / 0.2;
      }
      return price;
    },
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.payment-history {
  margin-top: 16px;
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  text-align: left;
}
.table-title {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.table-header {
  background: #f7f7f8;
  border-radius: 4px;
  padding: 16px 24px;
  margin-top: 24px;
  display: flex;
}
.table-data,
.table-head {
  width: 20%;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  text-align: left;
}
.medium-column {
  width: 30%;
  text-transform: capitalize;
}
.last-column {
  text-align: right;
  /* width: 10%; */
}
.table-row {
  padding: 32px 24px;
  display: flex;
}
.table-border {
  border-bottom: 1px solid #e4e5e7;
}
.table-data {
  color: #53585f;
}
.amount-column {
  color: #1b1e22;
}
.figure-column {
  font-weight: bold;
}
</style>
