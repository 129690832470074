<template>
  <div class="team-balance-card">
    <div class="title-flex">
      <div class="card-title">BALANCE</div>
      <div class="add-card-action">Withdraw</div>
    </div>
    <div class="team-balance">
      <div class="div-title">Team’s Balance</div>
      <div class="balance-figure">
        {{
          totalBalance.toLocaleString(undefined, { minimumFractionDigits: 2 })
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalBalance: {
      required: true,
      type: Number,
    },
  },
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.team-balance-card {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  height: 197px;
}
.title-flex {
  display: flex;
  justify-content: space-between;
}
.card-title {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.add-card-action {
  cursor: pointer;
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
}
.team-balance {
  text-align: left;
  margin-top: 12px;
}
.div-title {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 49px;
}
.balance-figure {
  font-size: 24px;
  line-height: 29px;
  color: #1b1e22;
}
</style>
