<template>
  <div>
    <Toolbar stageTitle="Wallet">
      <div style="margin-top: 20px">
        <img src="/img/dashboard/notification-icon.svg" alt="" />
      </div>
    </Toolbar>
    <Loader v-if="teamIncome == null" />
    <div class="page-container" v-else @click="removePrompt">
      <div class="payment-overview">
        <div class="container-title">
          <div class="container-name">PAYMENTS</div>
          <div class="project-switch" @click="stayTop($event)">
            <div class="select-dropdown" v-if="currentProjectId">
              {{ currentProjectId.project_name }}
              <img src="/img/dashboard/drop-down.svg" alt="" />
            </div>
            <div class="dropdown-list" v-if="isProjects && projects.length > 0">
              <div
                v-for="(project, index) in projects"
                class="project-list"
                :key="index"
                @click="switchProject(project)"
              >
                {{ project.project_name }}
              </div>
            </div>
          </div>
        </div>
        <div class="project-earning">
          <div class="amount-paid">
            <div class="box-title">Total amount paid</div>
            <div class="box-figure">
              ${{
                getCurrentProject().amountPaid.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              }}
            </div>
          </div>
          <div class="team-received">
            <div class="box-title">
              Your team received (
              {{
                projects[0].percentage_cut
                  ? `${100 - projects[0].percentage_cut}%`
                  : ""
              }}
              )
            </div>
            <div class="box-figure" style="color: #54cf94">
              ${{
                getCurrentProject().teamCut.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              }}
            </div>
          </div>
          <div class="grupa-cut">
            <div class="box-title">
              Grupa got ({{
                projects[0].percentage_cut
                  ? `${projects[0].percentage_cut}%`
                  : ""
              }})
            </div>
            <div class="box-figure" style="color: #d45b54">
              ${{
                (getCurrentProject().amountPaid * 0.2).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                  }
                )
              }}
            </div>
          </div>
        </div>
        <div class="payment-history">
          <PaymentTable v-bind="{ teamIncome, currentProjectId }" />
        </div>
      </div>
      <div class="wallet-balance">
        <TeamBalance v-bind="{ totalBalance }" />
        <TeamWithdrawal />
      </div>
    </div>
  </div>
</template>

<script>
import { getDev } from "@/api";
import { teamWallet } from "@/api/team";
import Toolbar from "@/components/toolbar/generalToolBar";
import Loader from "@/components/general/centerLoader";
import PaymentTable from "./paymentTable";
import TeamBalance from "./teamBalance";
import TeamWithdrawal from "./teamWithdrawals";
export default {
  props: {},
  components: {
    Toolbar,
    Loader,
    PaymentTable,
    TeamBalance,
    TeamWithdrawal,
  },
  data: () => ({
    devIncome: null,
    teamIncome: null,
    projects: null,
    currentProjectId: null,
    isProjects: false,
  }),
  created() {
    this.getTeamProjects();
  },
  methods: {
    async getTeamProjects() {
      await getDev()
        .then((res) => {
          if (res.data) {
            this.projects = res.data.data.projects;
            if (this.projects.length > 0) {
              this.currentProjectId = this.projects[this.projects.length - 1];
            }
            this.$developConsole(res.data.data, "Team projects");
            this.fetchDevIncome();
          }
        })
        .catch((err) => {
          this.$developConsole(err);
        });
    },
    fetchDevIncome() {
      teamWallet()
        .then((response) => {
          if (response.data.status == 1) {
            let payment = response.data.data;
            this.teamIncome = payment.reverse();
            this.getCurrentProject();
            this.$developConsole(
              response.data.data,
              "List of team's transaction"
            );
          } else {
            this.$developConsole("An error occur....", "Error");
          }
        })
        .catch((err) => {});
    },
    getCurrentProject() {
      let last = this.currentProjectId;
      if (this.teamIncome) {
        var total = 0;
        for (var i = 0; i < this.teamIncome.length; i++) {
          if (this.teamIncome[i].project_id == last.id) {
            total = total + this.teamIncome[i].amount;
          } else {
            total = total + 0;
          }
        }
        return {
          amountPaid: total * (100 / 80),
          teamCut: total,
          grupaCut: total,
        };
      } else {
        return {
          amountPaid: 0,
          teamCut: 0,
          grupaCut: 0,
        };
      }
    },
    switchProject(project) {
      this.currentProjectId = project;
      this.isProjects = false;
    },
    removePrompt() {
      this.isProjects = false;
    },
    stayTop(event) {
      event.stopPropagation();
      this.isProjects = true;
    },
  },
  computed: {
    totalBalance() {
      if (this.teamIncome) {
        var total = 0;
        for (var i = 0; i < this.teamIncome.length; i++) {
          total = total + this.teamIncome[i].amount;
        }
        return total;
      } else {
        return 0;
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-container {
  padding: 32px;
  margin-top: 67px;
  min-height: calc(100vh - 67px);
  display: flex;
  background: #f7f7f8;
}
.payment-overview {
  width: 70%;
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  margin-right: 16px;
}
.wallet-balance {
  width: 30%;
}
.container-title {
  display: flex;
  justify-content: space-between;
}
.container-name {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.select-dropdown {
  background: #f7f7f8;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 12px;
  line-height: 140%;
  color: #1b1e22;
  cursor: pointer;
}
.select-dropdown img {
  margin-left: 11px;
}
.project-earning {
  margin-top: 56px;
  display: flex;
}
.box-title {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.grupa-cut,
.team-received,
.amount-paid {
  text-align: center;
}
.grupa-cut,
.team-received {
  margin-left: 96px;
}
.box-figure {
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #1b1e22;
  margin-top: 12px;
}
.payment-history {
  margin-top: 76px;
}
.dropdown-list {
  position: absolute;
  background: #f7f7f8;
  border-radius: 4px;
  min-width: 150px;
  z-index: 100;
  right: 0px;
}
.project-list {
  padding: 9px 24px;
  cursor: pointer;
}
.project-switch {
  position: relative;
}
</style>
