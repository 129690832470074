<template>
  <div class="team-drawal">
    <div class="table-title">WITHDRAWALS</div>
    <div class="payment-table">
      <div class="table-header">
        <div class="table-head">Amount</div>
        <div class="table-head">Date</div>
      </div>
      <!-- <div class="table-row table-border">
        <div class="table-data">Product Team</div>
        <div class="table-data">Product Team</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.team-drawal {
  margin-top: 16px;
  background: #ffffff;
  border-radius: 4px;
  text-align: left;
  padding: 24px;
}
.table-title {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.table-header {
  background: #f7f7f8;
  border-radius: 4px;
  padding: 16px 24px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.table-data,
.table-head {
  /* width: 50%; */
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  text-align: left;
}
.table-row {
  padding: 32px 24px;
  display: flex;
  justify-content: space-between;
}
.table-border {
  border-bottom: 1px solid #e4e5e7;
}
.table-data {
  color: #53585f;
}
</style>
