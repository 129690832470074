var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "team-balance-card" }, [
    _vm._m(0),
    _c("div", { staticClass: "team-balance" }, [
      _c("div", { staticClass: "div-title" }, [_vm._v("Team’s Balance")]),
      _c("div", { staticClass: "balance-figure" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.totalBalance.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })
            ) +
            "\n    "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-flex" }, [
      _c("div", { staticClass: "card-title" }, [_vm._v("BALANCE")]),
      _c("div", { staticClass: "add-card-action" }, [_vm._v("Withdraw")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }